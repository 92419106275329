:root {
  --yellow-1: hsl(56, 70%, 85%);
  --yellow-2: hsl(56, 70%, 60%);
  --yellow-3: hsl(56, 70%, 50%);
  --yellow-4: hsl(56, 70%, 45%);
  --error-red: #dc3545;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--yellow-3);
}

input, select {
  border-width: 1px;
  border-style: solid;
  border-color: var(--yellow-4) !important;
}

table {
  border-collapse: collapse;
  border-color: var(--yellow-4) !important;
}

.container {
  background: var(--yellow-1);
  padding: 10px;
  border-width: 0 0 3px 0;
  border-style: solid;
  border-color: var(--yellow-3) !important;
}

.lg-spacer {
  height: 20px;
}

.formErrors {
  color: var(--error-red);
  text-align: center;
}

.has-error {
  border-color: var(--error-red) !important;
  border-width: 3px !important;
}

.submit:disabled{
  background-color: #91bdff !important;
}

.chart {
  height: 500px;
  text-align: center;
  border-style: none;
}

.chartBox {
  height: 550px !important;
  padding: 10px 0 0 0  !important;
}

.borderless {
  border-style: none;
}

.chart-title {
  margin-top: 3px;
}

.center-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

.centered {
  margin: auto !important;
}

.calc {
  padding-top: 15px;
}

.duration {
  padding: 0;
}

.full {
  width: 100%;
}

.half {
  width: 50%;
  float: left;
}

.dur-title-l {
  padding-right: 13px;
  height: 2rem;
  line-height: 2rem;
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: var(--yellow-4);
}

.dur-title-r {
  padding-left: 10px;
  height: 2rem;
  line-height: 2rem;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: var(--yellow-4);
}

.dur-pc-box-l {
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: var(--yellow-4);
}

.dur-pc-box-r {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: var(--yellow-4);
}

.day-bar {
  height: 30px;
  background: var(--yellow-2);
  float: right;
}

.day-bar-text-box {
  height: 30px;
  float: right;
  line-height: 30px;
  margin-right: 7px;
}

.dark-bar {
  height: 30px;
  background: #212529;
  float: left;
}

.dark-bar-text-box {
  height: 30px;
  float: left;
  line-height: 30px;
  margin-left: 7px;
}

.dur-bot-l {
  height: 15px;
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: var(--yellow-4);
}

.dur-bot-r {
  height: 15px;
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: var(--yellow-4);
}

.annotation {
  margin-top: 10px;
}

.footer-col {
  line-height: 40px;
  height: 40px;
}

.title {
  padding: 5px 10px 5px 10px;
  line-height: 53px;
  height: 63px;
}
